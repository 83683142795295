
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-dialog__body{
        display: flex;
        flex-direction: column;
    }
    /deep/.el-form{
        /*margin: 0 auto;*/
    }
    /deep/.el-dialog__header{
        background: #0200D8;
        .el-dialog__title{
            color: #ffffff;
            font-size: 16px;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
        }
    }
    /deep/.el-button--primary{
        background: #0200D8;
        border-color: #0200D8;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .competitionManagement{
        height: 100%;
        padding: 30px;
        .competitionManagement-header{
            display: flex;
            justify-content: space-between;
            .selectCompetition{
                color: #343442;
                font-size: 14px;
                font-weight:500;
            }
            div{
                .creationBtn{
                    /*width: 80px;*/
                    height: 36px;
                    color: #ffffff;
                    background: #1222D8;
                    border-color: #1222D8;
                    line-height: 0;
                    padding: 0 12px;
                }
            }

        }
    }
